import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import StreetLink from "./helpers/streetlink.component";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { withRouter } from "../common/with-router";
import { Tag } from "primereact/tag";
import { formatPrice } from "./helpers/utils";

class LinksList extends Component {
  constructor(props) {
    super(props);
    this.retrieveRecentLinks = this.retrieveRecentLinks.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.onChangeSearchLinkid = this.onChangeSearchLinkid.bind(this);
    this.onChangeSearchLinkText = this.onChangeSearchLinkText.bind(this);
    this.setCurrentIndex = this.setCurrentIndex.bind(this);
    


    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: 0,
      searchTitle: "",
      searchLinkedId: "",
      linkId: "",
    };
  }

  onChangeSearchLinkText(e) {
    const searchTitle = e.target.value;

    this.setState({
      linkId: searchTitle,
    });
  }

  setCurrentIndex(i){
    this.setState({
      currentIndex: i,
    });
  }

  componentDidMount() {
    const id = this.props.router.params.id;
    if (id) {
      
      this.setState({
        linkId: id,
      });
      TutorialDataService.getLink(id)
        .then((response) => {
          this.setState({
            tutorials: [response.data[0]],
            currentIndex: 0,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else this.retrieveRecentLinks();
  }

  onChangeSearchLinkid() {
    const searchTitle = this.state.linkId;
    if (searchTitle) {
      this.setState({
        tutorials: [],
        currentTutorial: [],
      });
      TutorialDataService.getLink(searchTitle)
        .then((response) => {
          this.setState({
            tutorials: [response.data[0]],
            currentIndex: 0,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  retrieveRecentLinks() {
    TutorialDataService.getTopRecentLinks()
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveLinksById(linkedId, index) {
    TutorialDataService.getLink(linkedId)
      .then((response) => {

        const sortedTutorials = response.data.sort((a, b) => 
          moment(b.updateddate).valueOf() - moment(a.updateddate).valueOf()
        );
        this.setState({
          currentTutorial: sortedTutorials,
          currentIndex: index,
        });

        
      })
      .catch((e) => {
        console.log(e);
      });
  }

  setActiveTutorial = (linkedid, index) => {
    this.retrieveLinksById(linkedid, index);
  };

  searchTitle() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });

    const paracall = [];

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        this.setState({
          tutorials: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderPropertyItem(tutorial) {
    const pricenumber = parseFloat(tutorial.price?.replace(/[^\d.-]/g, ''));
    const yield_value = this.calculateYield(pricenumber, tutorial.fmr);
    const daysColor = this.getDaysOnSiteColor(tutorial.active_days_on_site);
    const ppsqft = tutorial.ppsqft || 
      (tutorial.price && tutorial.squareFeet ? 
        `$${(tutorial.price / tutorial.squareFeet).toFixed(2)}` : null);

    return (
      <div className="p-2 border-bottom-1 surface-border">
        <div className="flex justify-content-between align-items-center mb-2">
          <div className="flex gap-3 align-items-center">
            {tutorial.thumbnail && (
              <img 
                src={tutorial.thumbnail} 
                alt="Property" 
                className="w-3rem h-3rem border-round"
                onError={(e) => e.target.style.display = 'none'} 
              />
            )}
            <div>
              <StreetLink
                url={`/cashflow-influencer/${tutorial.extractid}`}
                street={
                  `${tutorial.streetNumber} ${tutorial.street}, ${tutorial.city}, ${tutorial.state} ${tutorial.zipCode}`
                }
              />
              <a 
                href={tutorial.url}
                className="text-primary no-underline hover:underline ml-2"
              >
                <i className="pi pi-external-link" />
              </a>
            </div>
          </div>
          <div className="flex gap-2">
            {tutorial.status && (
              <Tag 
                value={tutorial.status} 
                severity={tutorial.status === 'NEW LISTING' ? 'success' : 'info'}
              />
            )}
            <Tag value={tutorial.source} />
          </div>
        </div>

        <div className="mb-2">
          <span className="mr-3">
          <span className="text-700">Price:</span> {tutorial.price ? formatPrice(tutorial.price) : 'N/A'}</span>
          <span className="mr-3">
            <span className="text-700">Est. Rental:</span> {tutorial.rentalestimate || tutorial.fmr ? `$${tutorial.rentalestimate || tutorial.fmr}` : 'N/A'}
          </span>
          {yield_value && (
            <Tag 
              value={`${yield_value}% yield`} 
              severity={yield_value > 10 ? 'success' : 'info'} 
              className="py-1"
            />
          )}
        </div>

        <div className="flex align-items-center gap-3">
          <span>
            {tutorial.bedrooms} beds • {tutorial.bathrooms} baths • {tutorial.squareFeet} sqft
          </span>
          {tutorial.year_built && (
            <span className="text-500">Built: {tutorial.year_built}</span>
          )}
          {tutorial.property_tax_rate && (
            <span className="text-500">Tax Rate: {tutorial.property_tax_rate}%</span>
          )}
          {tutorial.hoa && (
            <span className="text-500">HOA: ${tutorial.hoa}</span>
          )}
          <Tag 
            value={`${tutorial.active_days_on_site} days on site`} 
            severity={daysColor}
            className="py-1"
          />
        </div>

        <div className="flex justify-content-between align-items-center mt-2 text-500">
          <span>Last Updated: {moment(tutorial.updateddate).format("LL")}</span>
          {ppsqft && (
            <span>${ppsqft}/sqft</span>
          )}
        </div>
      </div>
    );
  }

  calculateYield(price, fmr) {
    if (!price || !fmr) return null;
    return ((fmr * 12 / price) * 100).toFixed(1);
  }

  getDaysOnSiteColor(days) {
    if (days <= 30) return 'success';
    if (days <= 90) return 'warning';
    if (days <= 180) return 'info';
    return 'danger';
  }


  render() {
    const { tutorials, currentTutorial, currentIndex, linkId } = this.state;
    const createDynamicTabs = () => {
      return tutorials.map((tab, i) => {
        const linkedid = tab.linkedid;
        return (
          <AccordionTab
            key={tab.linkedid}
            header={tab.linkedid}
            activeIndex={currentIndex} 
            onChange={() => this.setCurrentIndex(i)}
            onClick={() => this.setActiveTutorial(linkedid, i)}
          >
           {currentTutorial &&
              currentTutorial.map((tutorial, index) => (
                <div key={index} className="list-group-item">
                  {this.renderPropertyItem(tutorial)}
                </div>
              ))}
          </AccordionTab>
        );
      });
    };
    return (
      <div className="container ">
        <div className="list row">
          <div className="col-md-12">
            <div className="text-600 font-bold text-6xl mb-4 text-center">
              Links List{" "}
            </div>
            <div className="row">
              <div className="col-md-8">
                <InputText
                  placeholder="Link Id"
                  value={linkId}
                  className="w-full"
                  onChange={this.onChangeSearchLinkText}
                />
              </div>
              <div className="col-md-4">
                <Button
                  label="Apply"
                  onClick={this.onChangeSearchLinkid}
                  className="p-button w-full"
                />
              </div>
            </div>
            <div className="card">
              <Accordion>{createDynamicTabs()}</Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LinksList);
