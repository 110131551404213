import { Tag } from "primereact/tag";


const getStatusText = (status) => {
    switch (status) {
      case "1":
      case "New Listing":
      case "NEW LISTING":
      case "ACTIVE-BRIGHT":
      case "Active":
        return "Active";
      case "2":
      case "5":
      case "Pending":
      case "ACTIVE UNDER CONTRACT-BRIGHT":
        return "Pending";
      case "3":
        return "sold";
      case "12":
        return "Coming Soon";
      case "6":
        return "Contingent";
      default:
        return status;
    }
  };

export const generatePropertyTags = (
    product,
    calcCaprate,
    monthlyPreTaxCashFlowValue
  ) => {
    const tags = [];
  
    // Calculate Cap Rate
    const capRate = (
      ((product.netAnnualRent - product.annualOperatingExpenses) /
      product.price) *
      100
    ).toFixed(2);
  
    const monthlyCashFlow = monthlyPreTaxCashFlowValue;
  
    // Cash Flow Tags
    if (monthlyCashFlow > 0) {
      tags.push({
        text: "Positive Cash Flow",
        color: "bg-green-100 text-green-800",
      });
    }
    if (monthlyCashFlow < 0) {
      tags.push({
        text: "Negative Cash Flow",
        color: "bg-red-100 text-red-800",
      });
    }
  
    // Size Tag
    if (product.squareFeet > 2000) {
      tags.push({ text: "Large", color: "bg-gray-100 text-gray-800" });
    }
  
    // Cap Rate Tag
    if (calcCaprate(product).capRate > 7) {
      tags.push({ text: "High Cap Rate", color: "bg-blue-100 text-blue-800" });
    }
  
    // Best Value Tag
    if (calcCaprate(product).capRate > 7 && monthlyCashFlow > 0) {
      tags.push({ text: "Best Value", color: "bg-yellow-100 text-yellow-800" });
    }
  
    // Starter Home Tag
    if (product.price <= 200000 && monthlyCashFlow > 0) {
      tags.push({ text: "Starter Home", color: "bg-cyan-100 text-cyan-800" });
    }
  
    // Multi-Unit Tag
    if (product.property_type?.toLowerCase().includes("multi-family")) {
      tags.push({ text: "Multi-Unit", color: "bg-indigo-100 text-indigo-800" });
    }
  
    // Low Vacancy Area Tag
    if (product.rental_vacancy_rate && product.rental_vacancy_rate < 5) {
      tags.push({
        text: "Low Vacancy Area",
        color: "bg-blue-100 text-blue-800",
      });
    }
  
    // New Listing Tag
    if (product.active_days_on_site && product.active_days_on_site < 7) {
      tags.push({ text: "New Listing", color: "bg-pink-100 text-pink-800" });
    }
  
    // Low Down Payment Tag
    if (product.downPayment <= 50000 && monthlyCashFlow > 0) {
      tags.push({
        text: "Low Down Payment",
        color: "bg-purple-100 text-purple-800",
      });
    }
  
    // High Leverage Tag
    const downPaymentPercentage = (product.downPayment / product.price) * 100;
    if (downPaymentPercentage < 20) {
      tags.push({
        text: "High Leverage",
        color: "bg-orange-100 text-orange-800",
      });
    }
  
    return tags;
  };


export const getStatusTag = (status) => {
    const statusText = getStatusText(status);
    const severity =
      {
        Active: "success",
        Pending: "warning",
        sold: "danger",
        "Coming Soon": "info",
        Contingent: "warning",
      }[statusText] || "info";

    return (
      <Tag
        value={statusText}
        severity={severity}
        className="text-sm font-medium mr-2"
        style={{ borderRadius: "4px" }}
      />
    );
  };

export   const formatPropertyType = (propertyType) => {
    if (!propertyType) return "";

    // Convert to uppercase for consistent matching
    const type = propertyType.toUpperCase();

    switch (type) {
      case "SINGLE_FAMILY_HOUSE":
      case "SINGLE_FAMILY":
      case "SINGLE_FAMILY_HOME":
      case "Single family":
      case "SINGLE FAMILY":
      case "RESIDENTIAL":
        return "Single Family Home";

      case "MULTI_FAMILY":
      case "MULTI_FAMILY_HOUSE":
      case "MULTI-FAMILY":
        return "Multi-Family Home";

      case "CONDO":
      case "CONDOMINIUM":
        return "Condo";

      case "TOWNHOUSE":
      case "TOWNHOME":
        return "Townhouse";

      case "APARTMENT":
      case "APT":
        return "Apartment";

      case "DUPLEX":
        return "Duplex";

      case "TRIPLEX":
        return "Triplex";

      case "QUADPLEX":
        return "Quadplex";

      case "MOBILE_HOME":
      case "MANUFACTURED_HOME":
        return "Mobile Home";

      case "COMMERCIAL":
        return "Commercial Property";

      case "LAND":
      case "VACANT_LAND":
        return "Vacant Land";

      case "MIXED_USE":
        return "Mixed-Use Property";

      default:
        // If no match, convert snake_case to Title Case
        return propertyType
          .split("_")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
    }
  };

  export const formatToLocaleString = (price) => {
    return price.toLocaleString();
  };

  export const formatToFloat = (selectedprice) => {
    // Remove commas from the price string
    const priceString = selectedprice.replace(/,/g, "");

    // Convert the cleaned-up string to a number
    return parseFloat(priceString);
  };

  export const getDaysDifference = (startDate, endDate) => {
    // Convert the dates to UTC to ensure accurate calculation
    const startUtc = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const endUtc = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    // Calculate the number of milliseconds in a day
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculate the difference in days
    return Math.floor((endUtc - startUtc) / oneDay);
  };

export // Function to parse the date string from the API into a Date object
const parseApiDateString = (apiDateString) => {
  // Split the date string into date and time parts
  const parts = apiDateString.split(" ");

  // Split the date part into year, month, and day
  const dateParts = parts[0].split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript
  const day = parseInt(dateParts[2]);

  // Split the time part into hours, minutes, seconds, and milliseconds
  const timeParts = parts[1].split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2].split(".")[0]); // Remove milliseconds
  const milliseconds = parseInt(timeParts[2].split(".")[1]); // Extract milliseconds

  // Create and return the Date object
  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
};