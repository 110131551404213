export const formatPrice = (price) => {
  // If price is falsy (null, undefined, empty string, etc.), return "-"
  if (!price) return "-";

  // Remove non-numeric characters (e.g., "$", ",") and parse the number
  const number = parseFloat(price.replace(/[^\d.-]/g, ''));

  // If the number is invalid (NaN), return "-"
  if (isNaN(number)) return "-";

  // Format the number based on its magnitude
  if (number >= 1000000000) {
    return `$${(number / 1000000000).toFixed(2)}B`; // Billions
  } else if (number >= 1000000) {
    return `$${(number / 1000000).toFixed(2)}M`; // Millions
  } else if (number >= 1000) {
    return `$${(number / 1000).toFixed(2)}K`; // Thousands
  } else {
    // For numbers less than 1000, round and add commas
    return `$${Math.round(number).toLocaleString()}`;
  }
};