import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import PropertyMap from './mapstreet.component';

const PropertyDetails = ({ propertyDetails, handlePropertyInputChange, demographicsData }) => {
  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
      <h2 className="text-xl font-medium m-0">Property Details</h2>
        <span className="text-600">{propertyDetails.bedsBaths} • {propertyDetails.squareFeet} sqft</span>
      </div>
      
      <div className="surface-card p-3 border-round">
        {/* Address */}
        <div className="text-lg mb-3">
          {propertyDetails.address}
        </div>

        <div className="grid">
          {/* Left Column - Price and Rent Fields */}
          {/* Right Column - Thumbnail if available */}
          {propertyDetails.thumbnail && (
            <div className="col-12 md:col-6 p-2">
              <img 
                src={propertyDetails.thumbnail} 
                alt="Property"
                className="w-full h-64 object-cover rounded-lg shadow-sm"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/api/placeholder/800/600";
                }}
              />
            </div>
          )}
          
          <div className={`${propertyDetails.thumbnail ? 'col-12 md:col-6' : 'col-12'}`}>
  <div className="grid">
    {/* Purchase Price */}
    <div className={`${propertyDetails.thumbnail ? 'col-12' : 'col-12 md:col-6'} p-2`}>
      <span className="block text-600 text-sm mb-2">Purchase Price ($)</span>
      <InputNumber
        value={propertyDetails.purchasePrice}
        onValueChange={(e) => handlePropertyInputChange({
          target: { name: 'purchasePrice', value: e.value }
        })}
        mode="currency"
        currency="USD"
        locale="en-US"
        minFractionDigits={0}
        className="w-full"
      />
    </div>

    {/* Monthly Rent */}
    <div className={`${propertyDetails.thumbnail ? 'col-12' : 'col-12 md:col-6'} p-2`}>
      <span className="block text-600 text-sm mb-2">Monthly Rent ($)</span>
      <InputNumber
        value={propertyDetails.monthlyRent}
        onValueChange={(e) => handlePropertyInputChange({
          target: { name: 'monthlyRent', value: e.value }
        })}
        mode="currency"
        currency="USD"
        locale="en-US"
        minFractionDigits={0}
        className="w-full"
      />
      <div className="text-500 text-sm mt-2">
        Rent ${(propertyDetails.monthlyRent / propertyDetails.squareFeet).toFixed(2)}/sqft
      </div>
    </div>
  </div>
</div>

          
        </div>

        {/* Quick Stats - Optional */}
        <div className="flex justify-content-end mt-2">
          <span className="text-500 text-sm">
           Rent ${(propertyDetails.monthlyRent / propertyDetails.squareFeet).toFixed(2)}/sqft
          </span>
        </div>

        {/* Property Map */}
        {/* {demographicsData?.location?.latitude && demographicsData?.location?.longitude && (
          <PropertyMap 
            latitude={demographicsData?.location?.latitude}
            longitude={demographicsData?.location?.longitude}
          />
        )} */}

      </div>
    </div>
  );
};

export default PropertyDetails;