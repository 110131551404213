import React, { Component, useEffect } from "react";
import TutorialDataService from "../services/tutorial.service";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import RentVsBuyChart from "./helpers/rentvsbuycalc";
import { getStatusClassName } from "../common/helpers/functions";
import { useNavigate, useLocation } from "react-router-dom";
import PropertyAnalysis from "./helpers/singlepropertyreport.component";

function TutorialsListWrapper(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const isAdmin = queryParams.get("admin");
  const streetNumber = queryParams.get("streetNumber");
  const street = queryParams.get("street");
  const city = queryParams.get("city");
  const state = queryParams.get("state");
  const zipCode = queryParams.get("zipCode");

  // Construct the `place` object
  const place = {
    address_components: [
      { long_name: streetNumber, types: ["street_number"] },
      { long_name: street, types: ["route"] },
      { long_name: city, types: ["locality"] },
      { long_name: state, types: ["administrative_area_level_1"] },
      { long_name: zipCode, types: ["postal_code"] },
    ],
  };

  return (
    <TutorialsList
      location={location}
      navigate={navigate}
      {...props}
      isAdmin={isAdmin}
      streetNumber={streetNumber}
      street={street}
      city={city}
      state={state}
      zipCode={zipCode}
    />
  );
}

class TutorialsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    const isAdmin = Boolean(props.isAdmin);
    this.state = {
      tutorials: [],
      mergedListings: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
      isLoading: false,
      hasSearched: false,
      isAdmin: isAdmin,
      showAnalysisReport: false,
      analysisData: null,
      demographicsData: null,
    };
  }

  handlePropertySelect(property) {
    // Transform the property data into the format needed for analysis
    const analysisData = {
      propertyDetails: {
        address: `${property.streetNumber} ${property.street}, ${property.city}, ${property.state}, ${property.zipCode}`,
        purchasePrice: Number(property.price),
        monthlyRent: Number(property.rentalestimate) || Number(property.fmr),
        squareFeet: Number(property.squareFeet),
        bedsBaths: `${property.bedrooms} beds • ${property.bathrooms} baths`,
        yearBuilt: property.year_built,
        propertyType: property.property_type,
        averageHomeValue: Number(property.propertyTaxes?.Average_Home_Value),
        daysOnMarket: Number(property.active_days_on_site),
        status: property.status,
        thumbnail: property.thumbnail,
        url: property.url,
      },
      financialParams: {
        downPayment: 20,
        interestRate: Number(property.latestMortgageRates?.pmms30 || 7.04),
        loanTerm: 30,
        propertyTaxRate: Number(property.propertyTaxes?.Property_Tax_Rate),
        insuranceRate: 0.5,
        maintenanceRate: 5,
        vacancyRate:  property.rentalVacancyRate,
        propertyManagementRate: 10,
        appreciationRate: 3,
        rentGrowthRate: 2,
        expenseGrowthRate: 2.5,
        sellingCosts: 6,
        hoaMonthly: property.hoaFee || 0,
        utilitiesMonthly: 0,
        otherMonthlyExpenses: 0,
        closingCosts: 3,
        repairBudget: 0,
      },
    };

    const { showAnalysisReport } = this.state;

    this.setState({
      analysisData,
      showAnalysisReport: !showAnalysisReport,
      currentTutorial: property,
      currentIndex: this.state.mergedListings.indexOf(property),
    });
  }

  componentDidUpdate(prevProps) {
    // Destructure props and state
    const { streetNumber, street, city, state, zipCode } = this.props;
    const { hasSearched } = this.state;
  
    // Check if all address elements are present and valid
    const hasAllAddressElements = streetNumber && street && city && state && zipCode;
  
    // Check if address props have changed
    const addressChanged = 
      streetNumber !== prevProps.streetNumber ||
      street !== prevProps.street ||
      city !== prevProps.city ||
      state !== prevProps.state ||
      zipCode !== prevProps.zipCode;
  
    // Search logic
    if (hasAllAddressElements && !hasSearched) {
      // Construct mock address_components
      const addressComponents = [
        { types: ["street_number"], short_name: streetNumber },
        { types: ["route"], short_name: street },
        { types: ["locality"], short_name: city },
        { types: ["administrative_area_level_1"], short_name: state },
        { types: ["postal_code"], short_name: zipCode },
      ];
  
      this.searchTitle(addressComponents);
      this.setState({ hasSearched: true, showAnalysisReport: true });
    }
  }

  // Function to parse address components
  parseAddressComponents(components) {
    const address = {};
    if (components && components.length > 0) {
      components.forEach((component) => {
        const types = component.types;
        const longName = component.short_name;
        if (types.includes("street_number")) {
          address.streetNumber = longName;
        } else if (types.includes("route")) {
          address.street = this.formatStreet(longName);
        } else if (types.includes("locality")) {
          address.city = longName;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = longName;
        } else if (types.includes("postal_code")) {
          address.zipcode = longName;
        }
      });
    } else {
      console.log("components is undefined or empty");
    }
    return address;
  }

  formatStreet(street) {
    const streetWords = street.split(" ");

    let formattedStreet;

    switch (streetWords.length) {
      case 2:
        formattedStreet = streetWords[0]; // "123"
        break;
      case 3:
        formattedStreet = `${streetWords[0]} ${streetWords[1]}`; // "123 Main"
        break;
      case 4:
        formattedStreet = `${streetWords[0]} ${streetWords[1]} ${streetWords[2]}`; // "123 Main Street"
        break;
      case 5:
        formattedStreet = `${streetWords[0]} ${streetWords[1]} ${streetWords[2]} ${streetWords[3]}`; // "123 Main Street Apartment"
        break;
      default:
        // Handle other cases as needed
        break;
    }

    return formattedStreet;
  }

  onChangeSearchTitle(e) {
    const searchTitle = e;

    this.setState({
      searchTitle: searchTitle,
      isLoading: true,
    });

    this.searchTitle(e.address_components);
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
    this.handlePropertySelect(tutorial);
  }

  searchTitle(address_components) {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
      hasSearched: true,
    });

    const paracall = this.parseAddressComponents(address_components);

    TutorialDataService.makeInventoryRequest(paracall)
      .then((response) => {
        response.data.sort((a, b) => b.extractid - a.extractid);

        const mergedData = {};
        const originalCount = response.data.length;

        // Helper function to determine if a date is more recent
        const isMoreRecent = (date1, date2) => {
          return new Date(date1).getTime() > new Date(date2).getTime();
        };

        // Helper function to process numerical fields
        const processField = (
          existingValue,
          newValue,
          existingDate,
          newDate,
          fieldName
        ) => {
          // If we don't have an existing value, use the new value
          if (!existingValue) return newValue;

          // For price-related fields, use the most recent value
          const priceFields = ["price", "rent", "fmr"];
          if (priceFields.includes(fieldName.toLowerCase())) {
            return isMoreRecent(newDate, existingDate)
              ? newValue
              : existingValue;
          }

          // For other numerical fields, keep the non-null value
          if (newValue !== null && newValue !== "") {
            return newValue;
          }
          return existingValue;
        };

        response.data.forEach((row) => {
          const linkedid = row.linkedid;

          if (linkedid === null) {
            // New standalone record
            mergedData[row.extractid] = {
              ...row,
              _mergeSource: "new",
              _mergeDate: row.updateddate || row.createdate,
            };
          } else if (!mergedData[linkedid]) {
            // First occurrence of a linked record
            mergedData[linkedid] = {
              ...row,
              _mergeSource: "linked_new",
              _mergeDate: row.updateddate || row.createdate,
            };
          } else {
            // Merge with existing record
            const existingData = mergedData[linkedid];
            const existingDate = existingData._mergeDate;
            const newDate = row.updateddate || row.createdate;

            for (const key in row) {
              if (Object.prototype.hasOwnProperty.call(row, key)) {
                const newValue = row[key];
                const existingValue = existingData[key];

                if (newValue !== null && newValue !== "") {
                  existingData[key] = processField(
                    existingValue,
                    newValue,
                    existingDate,
                    newDate,
                    key
                  );

                  // Track merged fields
                  if (existingData[key] !== existingValue) {
                    existingData._mergedFields =
                      existingData._mergedFields || {};
                    existingData._mergedFields[key] = true;
                    existingData._mergeSource = "merged";
                  }
                }
              }
            }

            // Update merge date if we used any newer values
            if (isMoreRecent(newDate, existingDate)) {
              existingData._mergeDate = newDate;
            }
          }
        });

        // Convert to array and sort by date
        let mergedDataArray = Object.values(mergedData);
        mergedDataArray.sort(
          (a, b) =>
            new Date(b._mergeDate).getTime() - new Date(a._mergeDate).getTime()
        );

        const { showAnalysisReport } = this.state;

        this.setState({
          tutorials: response.data,
          mergedListings: mergedDataArray,
          isLoading: false,
          showAnalysisReport: !showAnalysisReport,
          dataMergeStats: {
            originalCount,
            afterMerge: mergedDataArray.length,
          },
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
      });

    TutorialDataService.makeDemographicsRequest(paracall)
      .then((response) => {
        this.setState({
          demographicsData: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      tutorials,
      currentIndex,
      isLoading,
      mergedListings,
      isAdmin,
      showAnalysisReport,
      analysisData,
      demographicsData,
      searchTitle
    } = this.state;
    return (
      <>
        <div className="surface-ground min-h-screen">
          {/* Hero Section */}
          <div className="surface-section px-4 py-4  md:px-6 lg:px-8">
            <div className="text-center mb-6">
              <h1 className="text-900 text-4xl font-medium mb-3">
                Real Estate Investment Analysis
              </h1>
              <span className="text-600 text-xl">
                Find and analyze your next investment property
              </span>
            </div>

            <div className="list row">
              <div className="col-md-12">
                <div className="input-group mb-3">
                  <Autocomplete
                    className="form-control pac-target-input"
                    apiKey="AIzaSyBrqfpgLEI9L-coO0abNL3pfjaS2F2TKfM"
                    onPlaceSelected={(place) => {
                      this.onChangeSearchTitle(place);
                    }}
                    options={{
                      types: ["address"],
                      fields: ["address_components"],
                      componentRestrictions: { country: "us" },
                    }}
                  />
                </div>
              </div>
              {isAdmin && (
                <div className="col-12">
                  <h4>Search Results</h4>
                  {isLoading && (
                    <div className="spinner-container">
                      <ProgressSpinner />
                    </div>
                  )}
                  <ul className="list-group">
                    {tutorials.map((tutorial, index) => (
                      <li
                        className={`list-group-item p-2 border-start-0 border-end-0 ${
                          index === currentIndex ? "active bg-light" : ""
                        }`}
                        onClick={() => this.setActiveTutorial(tutorial, index)}
                        key={index}
                      >
                        <div className="d-flex justify-content-between align-items-baseline mb-1">
                          <h6
                            className={`mb-0 ${getStatusClassName(
                              tutorial.status
                            )}`}
                          >
                            {tutorial.streetNumber} {tutorial.street}{" "}
                            {tutorial.city} {tutorial.state} {tutorial.zipCode}
                          </h6>
                          <small className="text-muted">
                            {moment(tutorial.updateddate).format("MMM D, YYYY")}
                          </small>
                        </div>

                        <div className="row g-2">
                          <div className="col-md-8">
                            <small className="text-secondary">
                              beds - {tutorial.bedrooms} | baths -{" "}
                              {tutorial.bathrooms} | sqFt -{" "}
                              {tutorial.squareFeet} | year -{" "}
                              {tutorial.year_built}
                            </small>
                          </div>
                          <div className="col-md-4 text-md-end">
                            <small className="text-success fw-semibold">
                              Price - ${Number(tutorial.price).toLocaleString()}
                            </small>
                            <small className="text-muted ms-2">
                              |{" "}
                              <a
                                href={tutorial.url}
                                className="text-decoration-none"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {tutorial.source}
                              </a>{" "}
                              |
                              <span
                                className={`ms-1 ${getStatusClassName(
                                  tutorial.status
                                )}`}
                              >
                                {tutorial.status}
                              </span>
                            </small>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-1">
                          <small className="text-secondary">
                            Rent - $
                            {Number(tutorial.rentalestimate).toLocaleString()} |
                            Fmr - ${tutorial.fmr}
                          </small>
                          <small className="text-muted">
                            ID:{" "}
                            <a
                              href={"/links/" + tutorial.linkedid}
                              className="text-decoration-none"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {tutorial?.linkedid?.substring(0, 8)}...
                            </a>
                          </small>
                        </div>
                      </li>
                    ))}
                    {tutorials.length === 0 && (
                      <li className="list-group-item text-center py-4 text-muted">
                        {this.state.hasSearched
                          ? "No results found."
                          : "Please enter an address to search for properties."}
                      </li>
                    )}
                  </ul>
                  {mergedListings.length > 0 && <h4>Merged List</h4>}
                  <ul className="list-group">
                    {mergedListings.map((tutorial, index) => (
                      <li
                        className={`list-group-item p-2 border-start-0 border-end-0 ${
                          index === currentIndex ? "active bg-light" : ""
                        }`}
                        onClick={() => this.setActiveTutorial(tutorial, index)}
                        key={index}
                      >
                        <div className="d-flex justify-content-between align-items-baseline mb-1">
                          <h6
                            className={`mb-0 ${getStatusClassName(
                              tutorial.status
                            )}`}
                          >
                            {tutorial.streetNumber} {tutorial.street}{" "}
                            {tutorial.city} {tutorial.state} {tutorial.zipCode}
                          </h6>
                          <small className="text-muted">
                            {moment(tutorial.updateddate).format("MMM D, YYYY")}
                          </small>
                        </div>

                        <div className="row g-2">
                          <div className="col-md-8">
                            <small className="text-secondary">
                              beds - {tutorial.bedrooms} | baths -{" "}
                              {tutorial.bathrooms} | sqFt -{" "}
                              {tutorial.squareFeet} | year -{" "}
                              {tutorial.year_built}
                            </small>
                          </div>
                          <div className="col-md-4 text-md-end">
                            <small className="text-success fw-semibold">
                              Price - ${Number(tutorial.price).toLocaleString()}
                            </small>
                            <small className="text-muted ms-2">
                              |{" "}
                              <a
                                href={tutorial.url}
                                className="text-decoration-none"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {tutorial.source}
                              </a>{" "}
                              |
                              <span
                                className={`ms-1 ${getStatusClassName(
                                  tutorial.status
                                )}`}
                              >
                                {tutorial.status}
                              </span>
                            </small>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-1">
                          <small className="text-secondary">
                            Rent - $
                            {Number(tutorial.rentalestimate).toLocaleString()} |
                            Fmr - ${tutorial.fmr}
                          </small>
                          <small className="text-muted">
                            <a
                              className="text-decoration-none"
                              rel="noopener noreferrer"
                              onClick={() =>
                                this.setState({
                                  showAnalysisReport: !showAnalysisReport,
                                })
                              }
                            >
                              View Analysis Report |
                            </a>
                            ID:{" "}
                            <a
                              href={"/links/" + tutorial.linkedid}
                              className="text-decoration-none"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {tutorial?.linkedid?.substring(0, 8)}...
                            </a>
                          </small>
                        </div>
                      </li>
                    ))}
                  </ul>

                  {/* Property Analysis Modal/Section */}
                  {showAnalysisReport && analysisData && (
                    <PropertyAnalysis
                      initialData={analysisData}
                      demographicsData={demographicsData}
                      onClose={() =>
                        this.setState({
                          showAnalysisReport: !showAnalysisReport,
                          analysisData: null,
                        })
                      }
                      onUpdateData={(newData) =>
                        this.setState({
                          analysisData: newData,
                        })
                      }
                    />
                  )}
                </div>
              )}

              {!isAdmin && (
                <div className="col-md-12">
                  {isLoading ? (
                    <div className="text-center py-5">
                      <ProgressSpinner />
                      <p className="text-muted mt-3">
                        Searching multiple sources...
                      </p>
                    </div>
                  ) : mergedListings.length > 0 ? (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <h4>Property Details</h4>
                      </div>
                      {mergedListings.map((tutorial, index) => (
                        <div className="col-12" key={index}>
                          <div
                            className={`card shadow-sm ${
                              index === currentIndex ? "border-primary" : ""
                            }`}
                            onClick={() =>
                              this.setActiveTutorial(tutorial, index)
                            }
                          >
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <h6 className="mb-1">
                                    {tutorial.streetNumber} {tutorial.street},{" "}
                                    {tutorial.city}, {tutorial.state} ,{" "}
                                    {tutorial.zipCode}
                                  </h6>
                                  <p className="text-muted mb-0"></p>
                                </div>
                                <span
                                  className={`badge ${getStatusClassName(
                                    tutorial.status
                                  )}`}
                                >
                                  {tutorial.status}
                                </span>
                              </div>

                              <div className="row g-3">
                                <div className="col-md-4">
                                  <div className="card bg-light">
                                    <div className="card-body py-2">
                                      <h6 className="mb-1">Property Details</h6>
                                      <p className="mb-0 small">
                                        {tutorial.bedrooms} beds •{" "}
                                        {tutorial.bathrooms} baths
                                        <br />
                                        {tutorial.squareFeet} sqft • Built{" "}
                                        {tutorial.year_built}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="card bg-light">
                                    <div className="card-body py-2">
                                      <h6 className="mb-1">Price</h6>
                                      <p className="mb-0">
                                        <span className="h5 text-success">
                                          $
                                          {Number(
                                            tutorial.price
                                          ).toLocaleString()}
                                        </span>
                                        <br />
                                        <small className="text-muted">
                                          Updated{" "}
                                          {moment(tutorial.updateddate).format(
                                            "MMM D, YYYY"
                                          )}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="card bg-light">
                                    <div className="card-body py-2">
                                      <h6 className="mb-1">Rental Data</h6>
                                      <p className="mb-0 small">
                                        Est. Rent: $
                                        {Number(
                                          tutorial.rentalestimate
                                        ).toLocaleString()}
                                        <br />
                                        FMR: ${tutorial.fmr}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3 d-flex justify-content-between align-items-center">
                                <a
                                  className="btn btn-sm btn-outline-primary"
                                  rel="noopener noreferrer"
                                  onClick={() =>
                                    this.setState({
                                      showAnalysisReport: !showAnalysisReport,
                                    })
                                  }
                                >
                                  View Analysis Report
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* Property Analysis Modal/Section */}
                          {showAnalysisReport && analysisData && (
                            <PropertyAnalysis
                              initialData={analysisData}
                              demographicsData={demographicsData}
                              onClose={() =>
                                this.setState({
                                  showAnalysisReport: !showAnalysisReport,
                                  analysisData: null,
                                })
                              }
                              onUpdateData={(newData) =>
                                this.setState({
                                  analysisData: newData,
                                })
                              }
                            />
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    
                    this.state.hasSearched && (
                      <div className="text-center py-5">
                        <p className="text-muted mb-3">
                          No properties found at this address.
                        </p>
                        <div className="items-center">
                        <span
                          className="text-sm text-gray-500"
                          >
                            Try searching with a different address format
                          </span>
                          <p className="text-sm text-gray-500">
                            Example: "123 Main St" or "123 Main Street"
                          </p>                          
                          <span className="text-gray-400 text-sm my-1">
                            {" "}
                            or{" "}
                          </span>
                          <a
                            href={`/leads?city=${this.parseAddressComponents(searchTitle?.address_components).city}&state=${this.parseAddressComponents(searchTitle?.address_components).state}`}
                            className="text-blue-600 hover:text-blue-800 hover:underline"
                          >
                            View Leads in {this.parseAddressComponents(searchTitle?.address_components).city}, {this.parseAddressComponents(searchTitle?.address_components).state} →
                          </a>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TutorialsListWrapper;
