import React, { useState, useEffect } from 'react';
import { Home, TrendingUp, DollarSign, Award, Timer, Trophy } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from '../../../ui/alert';

// API configuration
const JSONBIN_API_KEY = '$2a$10$8YGp8RlhBCacyWYIUKqUjO0tx7SVJeJGJRhDrkCAtr9SRjZmCGiEe';  // Replace with your actual API key
const BIN_ID = '67af6e58e41b4d34e48e0001'; // Replace with your actual bin ID

const FliporRentGame = () => {
  const GOAL = 1000000; // $1M goal
  const [netWorth, setNetWorth] = useState(100000);
  const [rounds, setRounds] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [event, setEvent] = useState(null);
  const [property, setProperty] = useState(null);
  const [lastProfit, setLastProfit] = useState(null);
  const [scores, setScores] = useState([]);
  const [showSubmitScore, setShowSubmitScore] = useState(false);
  const [startTime] = useState(Date.now());
  const [gameResult, setGameResult] = useState(null);
  
  const generateProperty = () => {
    const basePrice = Math.floor(Math.random() * 300000) + 100000;
    return {
      price: basePrice,
      rent: Math.floor(basePrice * (0.005 + Math.random() * 0.005)),
      trend: Math.random() > 0.5 ? 'rising' : 'falling',
      condition: ['excellent', 'good', 'fair', 'poor'][Math.floor(Math.random() * 4)]
    };
  };

  // Fetch the current scores when the component mounts
  useEffect(() => {
    const initScores = async () => {
      const initialScores = await fetchScores();
      setScores(initialScores);
    };
    initScores();
    setProperty(generateProperty());
  }, []);

  const fetchScores = async () => {
    try {
      const response = await fetch(
        `https://api.jsonbin.io/v3/b/${BIN_ID}/latest`,
        {
          headers: {
            'X-Master-Key': JSONBIN_API_KEY
          }
        }
      );
      const data = await response.json();
      return data.record.scores || [];
    } catch (error) {
      console.error('Error fetching scores:', error);
      return [];
    }
  };

  const updateScores = async (newScore, existingScores) => {
    // Append the new score to the existing scores,
    // sort them (if needed), and keep the top 1000 scores.
    const updatedScores = [...existingScores, newScore]
      .sort((a, b) => b.netWorth - a.netWorth)
      .slice(0, 1000);
  
    try {
      await fetch(
        `https://api.jsonbin.io/v3/b/${BIN_ID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Master-Key': JSONBIN_API_KEY
          },
          body: JSON.stringify({ scores: updatedScores })
        }
      );
      return updatedScores;
    } catch (error) {
      console.error('Error updating scores:', error);
      return existingScores;
    }
  };

  const submitScore = async (playerName) => {
    const timeTaken = Math.floor((Date.now() - startTime) / 1000);
    const newScore = {
      playerName,
      netWorth,
      rounds,
      timeTaken,
      timestamp: Date.now()
    };
  
    const updatedScores = await updateScores(newScore, scores);
    setScores(updatedScores);
    setShowSubmitScore(false);
  };

  const marketEvents = [
    { text: 'Housing Boom: Property values increase by 20%', effect: 1.2, type: 'multiplier' },
    { text: 'Market Crash: Property values decrease by 15%', effect: 0.85, type: 'multiplier' },
    { text: 'Bad Tenants: $5K in damage repairs', effect: -5000, type: 'fixed' },
    { text: 'Interest Rate Hike: Financing gets more expensive', effect: 0.95, type: 'multiplier' },
    { text: 'Property Tax Increase: Additional expenses', effect: -2000, type: 'fixed' },
    { text: 'Neighborhood Improvement: Property value rises', effect: 1.1, type: 'multiplier' }
  ];

  const handleDecision = (decision) => {
    const randomEvent = marketEvents[Math.floor(Math.random() * marketEvents.length)];
    setEvent(randomEvent);
    
    let profit = 0;
    
    if (decision === 'flip') {
      const renovationCost = property.price * 0.1;
      let salePrice = property.price * 1.25; // Base flip profit is 25%
      
      if (randomEvent.type === 'multiplier') {
        salePrice *= randomEvent.effect;
      }
      
      profit = salePrice - property.price - renovationCost;
      
      if (randomEvent.type === 'fixed') {
        profit += randomEvent.effect;
      }
    } else if (decision === 'rent') {
      let annualRent = property.rent * 12;
      let expenses = property.price * 0.05;
      
      if (randomEvent.type === 'multiplier') {
        annualRent *= randomEvent.effect;
      }
      
      profit = annualRent - expenses;
      
      if (randomEvent.type === 'fixed') {
        profit += randomEvent.effect;
      }
    }
    
    const newNetWorth = Math.round(netWorth + profit);
    setNetWorth(newNetWorth);
    setRounds(rounds + 1);
    setLastProfit(Math.round(profit));
    
    if (newNetWorth >= GOAL) {
      setGameOver(true);
      setGameResult('win');
      setShowSubmitScore(true);
    } else {
      setTimeout(() => {
        setProperty(generateProperty());
        setEvent(null);
      }, 2000);
    }
  };

  const progressPercentage = (netWorth / GOAL) * 100;

  const Leaderboard = ({ scores }) => (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center mb-4">
        <Trophy className="w-5 h-5 text-yellow-500 mr-2" />
        <h3 className="text-xl font-semibold text-gray-900">🏆 Leaderboard</h3>
      </div>
  
      <div className="max-h-96 overflow-y-auto">
        {scores.length === 0 ? (
          <div className="text-center text-gray-500 py-4">
            No scores yet. Be the first!
          </div>
        ) : (
          <table className="w-full text-left border-collapse">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="p-3 rounded-l-lg">Rank</th>
                <th className="p-3">Player</th>
                <th className="p-3 text-right">Net Worth</th>
                <th className="p-3 text-right">Rounds</th>
                <th className="p-3 text-right rounded-r-lg">Time</th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    index < 3
                      ? "bg-yellow-50 font-semibold" // Highlight top 3 players
                      : "bg-white"
                  }`}
                >
                  <td className="p-3">
                    <span
                      className={`inline-block w-6 h-6 text-center rounded-full text-white font-bold ${
                        index === 0
                          ? "bg-yellow-500"
                          : index === 1
                          ? "bg-gray-400"
                          : index === 2
                          ? "bg-orange-400"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      {index + 1}
                    </span>
                  </td>
                  <td className="p-3">{score.playerName}</td>
                  <td className="p-3 text-right font-semibold text-green-700">
                    ${score.netWorth.toLocaleString()}
                  </td>
                  <td className="p-3 text-right">{score.rounds}</td>
                  <td className="p-3 text-right">
                    {Math.floor(score.timeTaken / 60)}:
                    {(score.timeTaken % 60).toString().padStart(2, "0")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
  

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Flip or Rent?</h1>
        <p className="text-xl text-gray-600 italic">
          Make the right moves. Build your empire!
        </p>
        <div className="mt-4 text-gray-600">
          Goal: Reach $1,000,000 in as few rounds as possible
        </div>
      </header>

      <div className="bg-white rounded-lg shadow-lg px-6 py-8 max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Side - Game Content */}
          <div  className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6">
            {/* Progress Bar */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-gray-600 mr-4">Progress to $1M</span>
                <span className="text-sm font-medium  text-gray-900">
                  {progressPercentage.toFixed(1)}%
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                  style={{ width: `${Math.min(progressPercentage, 100)}%` }}
                ></div>
              </div>
            </div>

            {/* Net Worth & Rounds */}
            <div className="flex justify-between mb-4">
              <div className="flex items-center">
                <DollarSign className="mr-2 text-green-600" />
                <div>
                  <span className="text-xl font-semibold block">
                    Net Worth: ${netWorth.toLocaleString()}
                  </span>
                  {lastProfit !== null && (
                    <span
                      className={`text-sm ${lastProfit >= 0 ? "text-green-600" : "text-red-600"}`}
                    >
                      Last move: {lastProfit >= 0 ? "+" : ""}
                      {lastProfit.toLocaleString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center ml-2">
                <TrendingUp className="mr-2 text-blue-600" />
                <div>
                  <span className="text-xl block">Rounds: {rounds}</span>
                  <span className="text-sm text-gray-600">
                    ${(GOAL - netWorth).toLocaleString()} to go
                  </span>
                </div>
              </div>
            </div>

            {/* Property Details */}
            {property && !gameOver && (
              <div className="border-t border-b border-gray-200 py-4 my-4">
                <div className="flex items-start mb-4">
                  <Home className="mr-4 text-gray-600" />
                  <div>
                    <h3 className="text-lg font-semibold">Property Details</h3>
                    <p>Purchase Price: ${property.price.toLocaleString()}</p>
                    <p>Monthly Rent: ${property.rent.toLocaleString()}</p>
                    <p>Market Trend: {property.trend}</p>
                    <p>Condition: {property.condition}</p>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-center gap-4">
                  <button
                    onClick={() => handleDecision("flip")}
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    Flip It
                  </button>
                  <button
                    onClick={() => handleDecision("rent")}
                    className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition"
                  >
                    Rent It
                  </button>
                  <button
                    onClick={() => handleDecision("pass")}
                    className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transition"
                  >
                    Pass
                  </button>
                </div>
              </div>
            )}

            {/* Market Event Alert */}
            {event && (
              <Alert className="mt-4">
                <AlertTitle>Market Event</AlertTitle>
                <AlertDescription>{event.text}</AlertDescription>
              </Alert>
            )}

            {/* Game Over */}
            {gameOver && (
              <div className="text-center py-8">
                <Award className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
                <h2 className="text-2xl font-bold mb-2">Congratulations!</h2>
                <p className="text-lg mb-4">
                  You reached $1M in {rounds} rounds!
                </p>
                {showSubmitScore && gameResult === "win" && (
                  <SubmitScore onSubmit={submitScore} />
                )}
                <button
                  onClick={() => window.location.reload()}
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 mt-4 transition"
                >
                  Play Again
                </button>
              </div>
            )}
          </div>

          {/* Right Side - Leaderboard */}
          <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6">
          <Leaderboard scores={scores} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SubmitScore = ({ onSubmit }) => {
  const [playerName, setPlayerName] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(playerName);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <input
        type="text"
        value={playerName}
        onChange={(e) => setPlayerName(e.target.value)}
        placeholder="Enter your name"
        className="px-4 py-2 border rounded mr-2"
        required
        maxLength={20}
      />
      <button
        type="submit"
        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
      >
        Submit Score
      </button>
    </form>
  );
};

export default FliporRentGame;
